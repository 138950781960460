export const businessTypeObj = {
  b2b: 'b2b',
  b2c: 'b2c',
};

// Manage Access
export const userStatus = {
  Active: 'active',
  Inactive: 'inactive',
};

export const roleChangeType = {
  Add: 'add',
  Remove: 'remove',
};

export const settingTabs = {
  General: 'general',
  Privacy: 'privacy',
  Notification: 'notification',
  EmpDashboard: 'empDashboard',
  SalesData: 'salesData',
  ROICalculations: 'ROICalculations',
  DaysAging: 'DaysAging',
  ProductsAndServices: 'ProductsAndServices',
  SalesStageNames: 'SalesStageNames',
  Subscription: 'subscription',
  InviteUser: 'inviteUser',
  ManageAccess: 'manageAccess',
  Commission: 'commission',
  ConfirmSales: 'confirmSales',
  BusinessType: 'businessType',
  Contacts: 'contacts',
  Salaries: 'calaries',
  AddTeamMembers: 'addTeamMembers',
  Integration: 'integration',
  Timezone: 'timezone',
  Fiscal: 'fiscal',
  CronTime: 'cronTime',
};

export const SETTING_SIDEBAR = {
  GENERAL: 'General',
  USERS: 'Users',
  SALARIES_AND_COMMISSION: 'Salaries & Commissions',
  ADVANCED: 'Advanced',
  INTEGRATIONS: 'Integration',
  TEMPLATES: 'Templates',
  ADVANCED_IMPORT: 'Data Import',
};

export const roleType = {
  Manager: 'manager',
  Employee: 'employee',
  Admin: 'admin',
  Both: 'both',
};
